import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { IconButtonAnimate } from "src/components/animate";
import MenuPopover from "src/components/MenuPopover";
import useLocales from "src/hooks/useLocales";

export default function LanguagePopover() {
  const { allLang, onChangeLang, currentLang } = useLocales();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const setLoading = () => {
    navigate("/loading");
  };
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: "action.selected" }),
        }}
      >
        <img disabledEffect src={currentLang.icon} alt={currentLang.label} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                handleClose();
                setLoading();
              }}
            >
              <ListItemIcon>
                <img
                  disabledEffect
                  alt={option.label}
                  src={option.icon}
                  sx={{ width: 28, mr: 2, borderRadius: 0.3 }}
                />
              </ListItemIcon>

              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
