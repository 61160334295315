import { Box, IconButton, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
import { PATH_AUTH } from "src/routes/paths";

const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();

  return (
    <Box>
      <Box textAlign="right" sx={{ marginTop: "-2%" }}>
        <LanguagePopover />
        <Iconify icon="formkit:right" />
      </Box>
      {smUp && (
        <Typography variant="body2">
          {translate("auth.dont_have")}
          &nbsp;? &nbsp;&nbsp;
          <Link
            variant="subtitle2"
            component={RouterLink}
            to={PATH_AUTH.register}
          >
            {translate("auth.get_started")}
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default GetStartedTitle;
