import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const ProductRequest = Loadable(
  lazy(() => import("src/pages/admin/productRequest/index"))
);

const Product = [
  {
    path: "product-request",
    children: [{ index: true, element: <ProductRequest /> }],
  },
];

export default Product;
