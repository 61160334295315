import React from "react";
import { CURRENCY } from "src/config";

// currency obj ref site : https://www.eurochange.co.uk/travel/tips/world-currency-abbreviations-symbols-and-codes-travel-money

const obj = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  CNY: "¥",
  EGP: "£",
  INR: "₹",
  JPY: "¥",
  MEX: "Mex$",
};

export const Currency = ({ children }) => {
  const symbol = obj[CURRENCY];

  return (
    <>
      {symbol} {children}
    </>
  );
};
