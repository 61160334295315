import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "src/hooks/useAuth";
import * as Yup from "yup";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
};

const useLogin = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { setError, handleSubmit, setValue } = methods;
  const [secret, setSecret] = useState(null);

  const onSubmit = async (data) => {
    const { status, data: res, message, secret } = await login(data);
    if (status) {
      enqueueSnackbar(`Welcome ${res}`);
    } else if (secret) {
      setValue("secret", secret);
      setSecret(secret);
    } else {
      setError("afterSubmit", {
        message: message.message,
      });
      Object.entries(message?.errors).flatMap(([key, value]) =>
        setError(key, { message: value })
      );
      
    }
  };

  return { secret, onSubmit: handleSubmit(onSubmit), methods };
};

export default useLogin;
