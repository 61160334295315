import MenuPopover from "src/components/MenuPopover";
const isEn = localStorage.getItem("i18nextLng") === "en";
const TableMenu = ({ open, onClose, children, width = isEn ? 200 : 280 }) => (
  <MenuPopover
    open={Boolean(open)}
    anchorEl={open}
    onClose={onClose}
    anchorOrigin={{ vertical: "top", horizontal: "left" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    arrow="right-top"
    sx={{
      mt: -1,
      width: 160,
      "& .MuiMenuItem-root": {
        px: 1,
        typography: "body2",
        borderRadius: 0.75,
        "& svg": { mr: 2, width: 20, height: 20 },
      },
      width: width,
    }}
  >
    {children}
  </MenuPopover>
);

export default TableMenu;
