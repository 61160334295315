import { capitalCase } from "change-case";
import React from "react";
import { RHFSelect } from "src/components/hook-form";
import Map from "src/components/map";
import useLocales from "src/hooks/useLocales";
import { isBinary } from "src/utils/isBinary";

const AmountType = () => {
  const { translate } = useLocales();

  return (
    <RHFSelect
      name="payment_type"
      label={translate("userFinancial.eWallet.amountType")}
      size="small"
    >
      <Map
        list={isBinary() ? [...options, ...binary] : options}
        render={({ value, label }) => <Option value={value} label={label} />}
      />
    </RHFSelect>
  );
};

const Option = ({ value, label }) => {
  const { translate } = useLocales();

  return <option value={value}>{translate(label)}</option>;
};

const options = [
  { value: "all", label: "userFinancial.depositWallet.all" },
  {
    value: "deducted_by_admin",
    label: "userFinancial.depositWallet.deductedByAdmin",
  },
  {
    value: "credited_by_admin",
    label: "userFinancial.depositWallet.creditedByAdmin",
  },
  {
    value: "product_purchased",
    label: "latest.productPurchased",
  },
  {
    value: "fund_transfer",
    label: "userFinancial.depositWallet.fundTransfer",
  },
  {
    value: "deposit_wallet",
    label: "userFinancial.depositWallet.depositWallet",
  },

  // { value: "released", label: "userFinancial.depositWallet.released" },
  // { value: "pending", label: "userFinancial.depositWallet.pending" },
  // { value: "failed", label: "userFinancial.depositWallet.failed" },
  // { value: "rejected", label: "userFinancial.depositWallet.rejected" },
  // { value: "finished", label: "userFinancial.depositWallet.finished" },
  // { value: "approved", label: "userFinancial.depositWallet.approved" },

  // {
  //   value: "plan_purchase",
  //   label: "userFinancial.depositWallet.planPurchase",
  // },
  // {
  //   value: "fund_transfer",
  //   label: "userFinancial.depositWallet.fundTransfer",
  // },
  // {
  //   value: "self_transfer",
  //   label: "userFinancial.depositWallet.selfTransfer",
  // },
  // {
  //   value: "referral_bonus",
  //   label: "userFinancial.depositWallet.referralBonus",
  // },
  // {
  //   value: "achievement_bonus",
  //   label: "userFinancial.depositWallet.achievementBonus",
  // },
  // {
  //   value: "first_order_bonus",
  //   label: "userFinancial.depositWallet.firstOrderBonus",
  // },

  // {
  //   value: "binary_bonus",
  //   label: "userFinancial.depositWallet.binaryBonus",
  // },
];

const binary = [
  { value: "binary_bonus", label: "userFinancial.depositWallet.binaryBonus" },
];

export default AmountType;
